import './main.css';

import mitt, { type Emitter } from 'mitt';
import { Fragment, Suspense } from 'react';
import { Helmet } from 'react-helmet';

import { APP_BASE_URL } from '../config/common.config';
import type { RouteType } from './components/RoutesWithLayout/RoutesWithLayout';
import RoutesWithLayout from './components/RoutesWithLayout/RoutesWithLayout';
import { CartIdProvider } from './context/CartIdProvider/CartIdProvider';
import canUseDOM from './helpers/canUseDOM';
import routes from './routes/routes';
import GlobalStyles from './styles/GlobalStyles';

// We need to clean up our route definitions, routes are currently in js.
// convert to ts and ensure they work with server in future PR.
const appRoutes: RouteType[] = routes as unknown as RouteType[];

const App = () => (
  <Fragment key="app">
    <GlobalStyles key="global-styles" />
    <Helmet
      defaultTitle="Native Instruments"
      titleTemplate="Native Instruments | %s"
      key="helmet"
    >
      <link
        rel="shortcut icon"
        href={`${APP_BASE_URL}/typo3conf/ext/ni_assets/Resources/Public/Images/favicon.ico`}
      />
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
      <meta name="description" content="" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Helmet>
    <CartIdProvider>
      <Suspense fallback={null}>
        <RoutesWithLayout routes={appRoutes} key="routes-with-layout" />
      </Suspense>
    </CartIdProvider>
  </Fragment>
);

export default App;

if (canUseDOM()) {
  // To communicate with micro front-ends
  window.emitter ??= mitt();
}

declare global {
  interface Window {
    emitter: Emitter;
  }
}
