export const loadScript = (attrs: object): void => {
  const script: HTMLScriptElement = document.createElement('script');
  script.type = 'text/javascript';
  Object.keys(attrs).forEach((key) => {
    script[key] = attrs[key];
  });
  document.head.appendChild(script);
};

export const toExternalScript = (src: string): string =>
  `<script src="${src}"></script>`;

export const toExternalInlineScript = (txt: string): string =>
  `<script>${txt}</script>`;

export function parseConsentGroups(input: string | undefined) {
  const groupsString = input?.match(/groups=([^&]*)/);
  if (!groupsString) return {};

  const groupAssignments = groupsString[1].split(',');
  const groups = {};

  groupAssignments.forEach((group) => {
    const [key, value] = group.split(':');
    groups[key] = value === '1';
  });

  return groups;
}
