const APP_ENV_SETTINGS =
  typeof window !== 'undefined' ? window.APP_ENV_SETTINGS : {};
const NODE_ENV_SETTINGS = typeof process !== 'undefined' ? process.env : {};
const STORE = {
  ...NODE_ENV_SETTINGS,
  ...APP_ENV_SETTINGS,
};
const DEPLOYMENT_ENVIRONMENT = STORE.DEPLOYMENT_ENVIRONMENT || 'development';
const NODE_ENV = STORE.NODE_ENV || 'development';
const APP_PORT = STORE.APP_PORT || 3000;
const APP_BASE_URL = STORE.APP_BASE_URL || 'https://www.test.ni.de';
const GOOGLE_PLACES_API_KEY = STORE.GOOGLE_PLACES_API_KEY || null;
const CHARGEBEE_SITE_URL = STORE.CHARGEBEE_SITE_URL || null;
const CHARGEBEE_PUBLISHABLE_KEY = STORE.CHARGEBEE_PUBLISHABLE_KEY || null;
const CHARGEBEE_RETENTION_APP_ID = STORE.CHARGEBEE_RETENTION_APP_ID || null;
const CHARGEBEE_RETENTION_SCRIPT = STORE.CHARGEBEE_RETENTION_SCRIPT || null;

const FEATURE_TOKEN_COOKIE = 'FEATURE_TOKEN';
const FEATURE_TOKEN_HEADER = 'x-feature-token';
const APP_LANGUAGES = ['en', 'de', 'fr', 'es', 'jp', 'zh'];
const DIRECTUS_SUPER_CLIENT_URL_KEY = 'super_client_token';

const DEFAULT_LOCALE = 'en';
const APP_LANGUAGES_LABELS = {
  en: 'English',
  de: 'Deutsch',
  fr: 'Français',
  es: 'Español',
  jp: '日本語',
  zh: '简体中文',
};

// Language map used for Date.prototype.toLocaleDateString():
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString
// https://www.techonthenet.com/js/language_tags.php
const LANGUAGE_TAGS_MAP = {
  en: 'en-US',
  de: 'de-DE',
  fr: 'fr-FR',
  es: 'es-ES',
  jp: 'jp-JP',
  zh: 'zh-CH',
};

/* OneTrust cookie consent */
const CONSENT_COOKIE = 'OptanonConsent';

const KAMELEOON_SITE_CODE = STORE.KAMELEOON_SITE_CODE || 'l3n0djpu9w';
const KAMELEOON_SCRIPT_SOURCE =
  STORE.KAMELEOON_SCRIPT_SOURCE ||
  'https://l3n0djpu9w.kameleoon.io/kameleoon.js';
const KAMELEOON_IFRAME_URL = STORE.KAMELEOON_IFRAME_URL
  ? APP_BASE_URL + STORE.KAMELEOON_IFRAME_URL
  : `${APP_BASE_URL}/typo3conf/ext/ni_assets/Resources/Public/Scripts/kameleoon_static_file.html`;

module.exports = {
  COOKIE_DOMAIN: STORE.COOKIE_DOMAIN || '.test.ni.de',
  APP_BASE_URL,
  APP_LANGUAGES,
  APP_LANGUAGES_LABELS,
  LANGUAGE_TAGS_MAP,
  DEFAULT_LOCALE,
  APP_PORT,
  DEPLOYMENT_ENVIRONMENT,
  FEATURE_TOKEN_COOKIE,
  FEATURE_TOKEN_HEADER,
  DIRECTUS_SUPER_CLIENT_URL_KEY,
  NODE_ENV,
  STORE,
  GOOGLE_PLACES_API_KEY,
  CONSENT_COOKIE,
  CHARGEBEE_SITE_URL,
  CHARGEBEE_PUBLISHABLE_KEY,
  CHARGEBEE_RETENTION_SCRIPT,
  CHARGEBEE_RETENTION_APP_ID,
  KAMELEOON_SITE_CODE,
  KAMELEOON_SCRIPT_SOURCE,
  KAMELEOON_IFRAME_URL,
};
