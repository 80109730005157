import * as Sentry from '@sentry/react';
import type { Event } from '@sentry/react';
import type { RouterHistory } from '@sentry/react/types/reactrouter';
import Cookies from 'js-cookie';
import { Route } from 'react-router-dom';

import { DEPLOYMENT_ENVIRONMENT, SENTRY_URL } from '@config/client.config';

import { getLocaleFromPathname } from './locale';
import {
  SHOP_STORE_CODE_COOKIE,
  getLocalizationCountryCookie,
} from './localization';

declare global {
  interface Window {
    __BUILD_META__: { hash: string } | string;
  }
}

export const SentryRoute = Sentry.withSentryRouting(Route);
const NO_DATA = 'unknown';

export function initSentry({ history }: { history: RouterHistory }): void {
  Sentry.init({
    dsn: SENTRY_URL,
    environment: DEPLOYMENT_ENVIRONMENT,
    allowUrls: ['native-instruments.com'],
    ignoreErrors: [
      // Non-actionable network errors unhandled, mostly by third-parties.
      'AbortError',
      'Load failed',
      'Failed to fetch',
      "Failed to execute 'send' on 'XMLHttpRequest'",
      /Non-Error exception captured/,
      /Non-Error promise rejection captured/,
      'ResizeObserver loop completed with undelivered notifications',
    ],
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    ].concat(
      DEPLOYMENT_ENVIRONMENT === 'production' ? Sentry.replayIntegration() : [],
    ),
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event, hint) {
      const error = hint?.originalException;
      if (
        error instanceof Error &&
        error.message.includes(
          'This Suspense boundary received an update before it finished hydrating',
        )
      ) {
        // Keep only 5% of these errors
        if (Math.random() > 0.05) {
          return null; // Ignore
        }
      }
      return event;
    },
  });

  const appVersion =
    typeof window.__BUILD_META__ === 'object'
      ? window.__BUILD_META__.hash
      : NO_DATA;

  const scope = Sentry.getCurrentScope();
  scope.setTag('app_version', appVersion);
  scope.setTag('app_namespace', window.location.pathname.split('/')[2]);
  scope.setTag('app_language', getLocaleFromPathname(window.location.pathname));
  scope.setTag('app_country', getLocalizationCountryCookie() ?? NO_DATA);
  scope.setTag(
    'app_store_code',
    Cookies.get(SHOP_STORE_CODE_COOKIE) ?? NO_DATA,
  );
  scope.setTag('error_type', 'genericError');
}

export enum ClientErrorType {
  GENERIC = 'generic',
  FATAL = 'fatal',
  GRAPHQL = 'graphql',
}

class CustomError extends Error {
  public constructor({ name, message, stack }) {
    super(message);
    this.name = name;
    if (stack) {
      this.stack = stack;
    }
  }
}

interface TagInterface {
  name: string;
  value: string;
}

interface CaptureErrorInterface {
  name: Error['name'];
  message: Error['message'];
  stack?: Error['stack'];
  tags?: TagInterface[];
  extra?: Event['extra'];
}

export default ({
  name,
  message,
  stack,
  tags,
  extra,
}: CaptureErrorInterface): void => {
  Sentry?.withScope((scope) => {
    const error = new CustomError({
      name,
      message,
      stack,
    });

    if (tags) {
      tags.forEach(({ name: tagName, value }) => scope.setTag(tagName, value));
    }
    if (extra) {
      scope.setExtra('additionalData', extra);
    }

    Sentry.captureException(error);
  });
};
